import React from "react";
import cx from "classnames";
import * as Meta from "@elevio/kb-kit/lib/components/meta";
import * as Category from "@elevio/kb-kit/lib/components/category";
import * as Article from "@elevio/kb-kit/lib/components/article";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight';
function Breadcrumb({ className, crumbs, noLinks }) {
    return (<div id={cx("breadcrumbs", className)} className={cx("breadcrumbs", className)}>
      {crumbs.map((crumb, index) => (<React.Fragment key={index}>
          <Crumb crumb={crumb} className="breadcrumbs-link breadcrumb-color" noLinks={noLinks}/>
          {index < crumbs.length - 1 && (<span className="breadcrumbs-divider">
              <FontAwesomeIcon icon={faCaretRight} className="breadcrumbs-divider-icon"/>
            </span>)}
        </React.Fragment>))}
    </div>);
}
function Crumb({ noLinks, crumb, className }) {
    if (noLinks) {
        return <span className={className}>{crumb.title}</span>;
    }
    if (crumb.linkType === "article") {
        return (<Article.Link articleId={crumb.linkId} className={className}>
        {crumb.title}
      </Article.Link>);
    }
    if (crumb.linkType === "category") {
        return (<Category.Link categoryId={crumb.linkId} className={className}>
        {crumb.title}
      </Category.Link>);
    }
    return <Meta.HomeLink className={className}>{crumb.title}</Meta.HomeLink>;
}
export default Breadcrumb;
