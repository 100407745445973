import React from "react";
import { Trans } from "@elevio/kb-kit/lib/components/trans";
import { useCopy } from "@elevio/kb-kit/lib/hooks";
function ArticleActions() {
    const { onCopy, didCopy } = useCopy();
    return (<div id="article-actions" className="article-actions">
      <button onClick={(e) => onCopy(e)} className="article-actions-button">
        <svg width="24" height="24" viewBox="0 0 24 24" className="article-actions-icon">
          <path d="M9 1C7.89543 1 7 1.89543 7 3H6C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6V20C3 20.7957 3.31607 21.5587 3.87868 22.1213C4.44129 22.6839 5.20435 23 6 23H18C18.7957 23 19.5587 22.6839 20.1213 22.1213C20.6839 21.5587 21 20.7957 21 20V6C21 5.20435 20.6839 4.44129 20.1213 3.87868C19.5587 3.31607 18.7956 3 18 3H17C17 1.89543 16.1046 1 15 1H9ZM17 5C17 6.10457 16.1046 7 15 7H9C7.89543 7 7 6.10457 7 5H6C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21 6 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V6C19 5.73478 18.8946 5.48043 18.7071 5.29289C18.5196 5.10536 18.2652 5 18 5H17ZM9 4V5H15V4V3H9V4Z"/>
        </svg>
        {didCopy && (<div id="article-actions-tooltip" className="article-actions-tooltip">
            <Trans i18nKey="copied">Copied</Trans>
          </div>)}
      </button>
      <button onClick={window.print} className="article-actions-button">
        <svg width="24" height="24" viewBox="0 0 24 24" className="article-actions-icon">
          <path d="M5 2C5 1.44772 5.44772 1 6 1H18C18.5523 1 19 1.44772 19 2V8H20C20.7957 8 21.5587 8.31607 22.1213 8.87868C22.6839 9.44129 23 10.2043 23 11V16C23 16.7957 22.6839 17.5587 22.1213 18.1213C21.5587 18.6839 20.7957 19 20 19H19V22C19 22.5523 18.5523 23 18 23H6C5.44772 23 5 22.5523 5 22V19H4C3.20435 19 2.44129 18.6839 1.87868 18.1213C1.31607 17.5587 1 16.7956 1 16V11C1 10.2044 1.31607 9.44129 1.87868 8.87868C2.44129 8.31607 3.20435 8 4 8H5V2ZM7 18V21H17V18V15H7V18ZM19 17V14C19 13.4477 18.5523 13 18 13H6C5.44772 13 5 13.4477 5 14V17H4C3.73478 17 3.48043 16.8946 3.29289 16.7071C3.10536 16.5196 3 16.2652 3 16V11C3 10.7348 3.10536 10.4804 3.29289 10.2929C3.48043 10.1054 3.73478 10 4 10H6H18H20C20.2652 10 20.5196 10.1054 20.7071 10.2929C20.8946 10.4804 21 10.7348 21 11V16C21 16.2652 20.8946 16.5196 20.7071 16.7071C20.5196 16.8946 20.2652 17 20 17H19ZM17 3V8H7V3H17Z"/>
        </svg>
      </button>
    </div>);
}
export default ArticleActions;
