import React, { useState } from "react";
import { useArticles, useCategory } from "@elevio/kb-kit/lib/hooks";
import * as Article from "@elevio/kb-kit/lib/components/article";
import * as Articles from "@elevio/kb-kit/lib/components/articles";
function ArticleList({ className, categoryId, isRelated, isAside }) {
    const { totalCount } = useArticles();
    const [limit, setLimit] = useState(4);
    const category = useCategory({ categoryId, usePageCategory: false });
    if (totalCount === 0)
        return null;
    return (<div id={className} className={className}>
      <h3 className={!isRelated ? 'article-title' : ''}>
      {isRelated ? 'Related Articles' : category && category.title}
        {/* <CategoryTitle > {category && category.title} </CategoryTitle> */}
        {/* <Trans i18nKey="article.relatedTitle">></Trans> */}
      </h3>
      <ul className="article-list">

        <Articles.Loop limit={limit}>
          <>
            <Article.IsNotActive>
              <li className="article-list-item">
                <Article.Link className="article-list-link link-color">
                  <Article.Title />
                </Article.Link>
              </li>
            </Article.IsNotActive>
          </>
        </Articles.Loop>
        <li className="article-list-item">
          {limit < totalCount && <a className="article-list-link article-count" href="#" onClick={(e) => {
                e.preventDefault();
                setLimit(totalCount);
            }}>View all {isAside ? totalCount - 1 : totalCount} articles</a>}
        </li>
      </ul>
    </div>);
}
export default ArticleList;
