import React from 'react';
import cx from 'classnames';
import LoginLogout from './LoginLogout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
const AGENCY_CONTACT_US = 'https://www.cityofhenderson.com/government/contact-us';
function Footer({ className }) {
    return (<footer className={cx('footer', className)} data-testid="footer">
      <div id="footer-wrapper" className="footer-wrapper">
        <p className="footer-copyright">
          &copy; {new Date().getFullYear()} Comcate, Inc.
        </p>
        <nav className="footer-nav">
          <div id="unqiue-id-11" className="footer-link" onClick={() => window.open('https://www.facebook.com/bozemangov', '_blank')}>
            <FontAwesomeIcon icon={faFacebookF}/>
          </div>
          <div id="unqiue-id-12" className="footer-link" onClick={() => window.open('https://twitter.com/CityofBozeman', '_blank')}>
            <FontAwesomeIcon icon={faTwitter}/>
          </div>
          <div id="unqiue-id-13" className="footer-link" onClick={() => window.open('https://www.instagram.com/cityofbzn', '_blank')}>
            <FontAwesomeIcon icon={faInstagram}/>
          </div>
          <LoginLogout className="footer-link"/>
        </nav>
      </div>
    </footer>);
}
export default Footer;
